

















































































import { defineComponent } from "@vue/composition-api";
import { Button, Cell, CellGroup, Checkbox, CheckboxGroup, Dialog, Field, Form, Popup, Radio, RadioGroup, Toast } from "vant";
import axios from "@/helpers/axios";
import AddressPicker from "@/views/user/address/address.vue";
import Index from "@/exp/cart/index.vue";

interface Order {
  id: number;
  title: string;
  amount: number;
  orderNo: string;
  payTime: string;
}

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [Popup.name]: Popup,
    AddressPicker,
    Index
  },
  data() {
    return {
      step: 1, // 1/2 picker order / fill invoice info

      // pick order
      orderList: [],
      backAmount: 0,
      checkedOrderList: [] as Order[],

      // check&fill invoice info
      invoiceInfo: {},
      deliveryType: 0,
      deliveryTypeMsg: "",
      email: localStorage.email || "",
      addressId: 0,
      showAddressPicker: false,
      addressMsg: ""
    };
  },
  computed: {
    isAllOrderChecked(): boolean {
      return this.checkedOrderList.length === this.orderList.length;
    },
    totalCheckedAmount(): number {
      return this.checkedOrderList.reduce((sum, order) => {
        sum += order.amount;
        return sum;
      }, 0);
    },
    readyForApply(): boolean {
      if (this.deliveryType == 1 && this.email) {
        return true;
      } else if (this.deliveryType == 2 && this.addressId > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.getApplyInfo();
  },
  methods: {
    async getApplyInfo() {
      const data = await axios
        .post("/api/manage/invoice/applyInfo")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) {
        setTimeout(() => this.$router.go(-1), 1500);
        return;
      }
      this.backAmount = data.backAmount;
      this.orderList = data.orderList;
      this.invoiceInfo = data.invoiceInfo;
      if (data.userAddress) {
        this.onPickAddress(data.userAddress);
      }
      if (data.invoiceInfo.type == 4) {
        this.deliveryType = 2;
        this.deliveryTypeMsg = "纸质发票";
      } else {
        this.deliveryType = 1;
        this.deliveryTypeMsg = "电子发票";
      }
    },
    async applyInvoice() {
      const confirm = await Dialog.confirm({
        message: this.deliveryType == 2 ? "纸质发票将通过 顺丰到付 寄送给您" : "您确定邮箱 " + this.email + " 是否准确无误?"
      });
      if (!confirm) return;
      const res = await axios
        .post("/api/manage/invoice/applyInvoice", {
          orderAmount: this.totalCheckedAmount,
          backAmount: this.backAmount,
          deliveryType: this.deliveryType,
          addressId: this.addressId,
          email: this.email,
          orderIds: this.checkedOrderList
            .reduce((arr, order) => {
              arr.push(order.id);
              return arr;
            }, [] as number[])
            .join(",")
        })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      Toast.success("申请已提交");
      setTimeout(() => {
        this.$router.go(-1);
      }, 1500);
    },
    checkOrder(index: number) {
      const checkboxs = this.$refs.orderCheckboxes as Checkbox[];
      checkboxs[index].toggle();
    },
    checkAllOrder() {
      const checkboxGroup = this.$refs.orderCheckboxGroup as CheckboxGroup;
      checkboxGroup.toggleAll(!this.isAllOrderChecked);
    },
    onPickAddress(address: { province: string; city: string; area: string; detailAddress: string; phone: string; name: string; id: number }) {
      this.showAddressPicker = false;
      this.addressId = address.id;
      this.addressMsg = `收件人: ${address.name}, ${address.phone}
${address.province}${address.city}${address.area}${address.detailAddress}`;
    }
  }
});
